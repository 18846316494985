@import "app.scss";
/**************************************** LOGIN *************************************************/
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.25);
}

.wrap-login100 {
  width: 500px;
  border-radius: 10px;
  overflow: hidden;
  padding: 55px 55px 37px 55px;

  background: #81a864;
  background: -webkit-linear-gradient(top, #34472f, #81a864);
  background: -o-linear-gradient(top, #34472f, #81a864);
  background: -moz-linear-gradient(top, #34472f, #81a864);
  background: linear-gradient(top, #34472f, #81a864);
}

.wrap-login100 a{
  color: #fff;
}

.wrap-login100 a:hover{
  color: #d41818;
}
  /*------------------------------------------------------------------
  [ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-logo {
  font-size: 60px;
  color: #333333;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}

.login100-form-title {
  height: 60px;
  font-family: $font-family1;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  display: block;
}


/*------------------------------------------------------------------
[ Input ]*/

.login100-form .wrap-input100 {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.login100-form .input100 {
  font-family: $font-family2;
  font-size: 16px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 45px;
  background: #fff;
  padding: 0 5px 0 38px;
}

.login100-form .container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  margin: 30px auto 0 auto ;
  font-family: $font-family2;
  font-size: 16px;
  color: #555555;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 120px;
  height: 50px;
  border-radius: 25px;

  background: #81a864;
  background: -webkit-linear-gradient(bottom, #34472f, #81a864);
  background: -o-linear-gradient(bottom, #34472f, #81a864);
  background: -moz-linear-gradient(bottom, #34472f, #81a864);
  background: linear-gradient(bottom, #34472f, #81a864);
  position: relative;
  z-index: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background-color: #fff;
  top: 0;
  left: 0;
  opacity: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  color: #fff;
}

.login100-form-btn:hover:before {
  opacity: 0;
}
